
    import { Component, Prop, Vue } from 'vue-property-decorator';

    @Component
    export default class BoolIcon extends Vue {
        @Prop() flag!: boolean | null;

        get type() {
            if (this.flag) {
                return 'is-success';
            }

            return 'is-danger';
        }

        get iconName() {
            if (this.flag) {
                return 'checkbox-marked-circle';
            }

            return 'cancel';
        }
    }
