export enum THGUserTableColumn {
    VAT_NUMBER = 'vat_number',
    COMPANY_NAME = 'company_name',
    FIRST_NAME = 'first_name',
    LAST_NAME = 'last_name',
    STREET = 'street',
    ZIP_CODE = 'zip_code',
    CITY = 'city',
    PAYEE = 'payee',
    IBAN = 'iban',
    REGISTRATION_LIMIT = 'registration_limit',
    PROMO_NUMBER = 'promo_number',
    PROMO_STATUS = 'promo_status',
}
